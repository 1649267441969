import { useParams } from "react-router-dom";
import { fetchStore } from "../../lib/http/fetchStore";
import { Store } from "../../types/types";
import { useQuery } from "@tanstack/react-query";

type Configuration = {
  id?: string;
  urlSlug?: string;
};

/**
 * Grab the store from the url slug :storeId by default.
 * Given a store id, it will fetch the store of the given id.
 */
export const useStore = ({ id = "", urlSlug = "storeId" }: Configuration) => {
  const params = useParams<Record<string, string>>(); // @ToDo: Use a more specific type to represent the expected route params
  const storeId = getStoreId(id, params[urlSlug]);
  return useQuery<Store>(["store", storeId], () => fetchStore(storeId));
};

const getStoreId = (id: string, urlParameter: string | undefined): string => {
  const storeId = id || urlParameter || "";
  return storeId;
};
