import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Input,
  Spacer,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "../components/_ui/Label";
import { Link } from "../components/_ui/Link";
import { useSessionContentTemplate } from "../data/sessionDefaultData";
import { deleteStore } from "../lib/auth/deleteStore";
import { updateStore } from "../lib/auth/updateStore";
import { fetchStore } from "../lib/http/fetchStore";
import { markOnboard } from "../lib/http/markOnboard";
import { UpdateStoreRequest } from "../types";
import { Store } from "../types/types";
import { PageContainer } from "./PageContainer";

export const StorePage = () => {
  const sessionContentTemplate = useSessionContentTemplate();
  const { storeId } = useParams<{ storeId: string }>();
  const [store, setStore] = useState<Store>({
    id: "",
    orgId: "",
    name: "",
    customerUrl: "",
    updatedAt: "",
    ecomUrl: "",
    cacheDuration: 900,
    currencyDefault: "USD",
    edgeEnabled: false,
    personalizationEnabled: false,
    staticSiteEnabled: false,
    sessionContent: JSON.stringify([]),
    currencyMap: JSON.stringify({}),
    zoneId: "",
  });

  const showToast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;
    if (!ignore && storeId !== undefined) {
      fetchStore(storeId).then((data) => {
        let {
          id,
          orgId,
          name,
          customerUrl,
          updatedAt,
          ecomUrl,
          cacheDuration,
          currencyDefault,
          edgeEnabled,
          personalizationEnabled,
          staticSiteEnabled,
          sessionContent,
          currencyMap,
          zoneId,
        }: Store = data;

        // if any input value is null, set it to it's corresponding data type below
        if (currencyDefault === null) {
          currencyDefault = "";
        }

        if (sessionContent === null) {
          sessionContent = JSON.stringify([]);
        } else {
          sessionContent = JSON.stringify(sessionContent, null, 2);
        }

        if (currencyMap === null) {
          currencyMap = JSON.stringify({});
        } else {
          currencyMap = JSON.stringify(currencyMap, null, 2);
        }

        if (zoneId === null) {
          zoneId = "";
        }

        setStore((_previous) => ({
          id,
          orgId,
          name,
          customerUrl,
          updatedAt,
          ecomUrl,
          cacheDuration,
          currencyDefault,
          edgeEnabled,
          personalizationEnabled,
          staticSiteEnabled,
          sessionContent,
          currencyMap,
          zoneId,
        }));
      });
    }

    return () => {
      ignore = true;
    };
  }, [storeId]);

  const handleUpdateStore = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (storeId === undefined) return;

    // Build a new state object instead of mutating the existing state
    // from a string type into a JSON object type in the event that the user does not have a successful operation
    let {
      name,
      customerUrl,
      ecomUrl,
      cacheDuration,
      currencyDefault,
      edgeEnabled,
      personalizationEnabled,
      staticSiteEnabled,
      sessionContent,
      currencyMap,
      zoneId,
    }: UpdateStoreRequest = store;

    try {
      sessionContent = JSON.parse(sessionContent);
    } catch (error: unknown) {
      alert("Invalid JSON in Session Content: \n" + error);
      return void 0;
    }

    try {
      currencyMap = JSON.parse(currencyMap);
    } catch (error: unknown) {
      alert("Invalid JSON in Currency Map: \n" + error);
      return void 0;
    }

    cacheDuration = Number(cacheDuration);
    if (isNaN(cacheDuration)) {
      showToast({
        title: "Error: Cache Duration must be a number",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return void 0;
    }

    updateStore(storeId, {
      name,
      customerUrl,
      ecomUrl,
      cacheDuration,
      currencyDefault,
      edgeEnabled,
      personalizationEnabled,
      staticSiteEnabled,
      sessionContent,
      currencyMap,
      zoneId,
    })
      .then((response) => {
        showToast({
          title: "Submitted!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        showToast({
          title: "Error: " + error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const {
    name,
    customerUrl,
    ecomUrl,
    cacheDuration,
    currencyDefault,
    edgeEnabled,
    personalizationEnabled,
    staticSiteEnabled,
    sessionContent,
    currencyMap,
    zoneId,
  } = store;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStore((previous) => ({ ...previous, [name]: value }));
  };

  const handleIsChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const newValue = checked ? true : false;
    setStore((previous) => ({ ...previous, [name]: newValue }));
  };

  const handleTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = event.target;
    setStore((previous) => ({ ...previous, [name]: value }));
  };

  const handleDelete = () => {
    if (storeId === undefined) return;
    const result = window.confirm(
      "Are you sure you want to delete this store?"
    );
    if (result) {
      deleteStore(storeId)
        .then((response) => {
          showToast({
            title: "Success! Redirecting ...",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(() => {
            navigate("/stores");
          }, 2000);
        })
        .catch((error) => {
          showToast({
            title: "Error: " + error,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      return void 0;
    }
  };

  const handleMarkOnboard = () => {
    markOnboard(ecomUrl).then((response) => {
      console.log(response);
      showToast({
        title: `Marked ${ecomUrl} as onboarded`,
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    }).catch((error) => {
      showToast({
        title: "Error: " + error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };


  return (
    <PageContainer>
      <Heading pb={4} as="h1">
        Edit Store
      </Heading>
      <FormControl maxWidth={"40%"}>
        <form onSubmit={handleUpdateStore}>
          <Label htmlFor="name">Name</Label>
          <Input
            placeholder="Store Name"
            size="sm"
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            required
          />
          <Label htmlFor="customerUrl">Customer URL</Label>
          <Input
            placeholder="Customer URL"
            size="sm"
            type="text"
            name="customerUrl"
            value={customerUrl}
            onChange={handleChange}
            required
          />
          <Label htmlFor="ecomUrl">Ecom URL</Label>
          <Input
            placeholder="Ecom URL"
            size="sm"
            type="text"
            name="ecomUrl"
            value={ecomUrl}
            onChange={handleChange}
            required
          />
          <Label htmlFor="cacheDuration">Cache Duration</Label>
          <Input
            placeholder="Cache Duration"
            size="sm"
            type="number"
            name="cacheDuration"
            value={cacheDuration}
            onChange={handleChange}
          />
          <Label htmlFor="currencyDefault">Currency Default</Label>
          <Input
            placeholder="Currency Default 'USD'"
            size="sm"
            type="text"
            name="currencyDefault"
            value={currencyDefault}
            onChange={handleChange}
          />
          <Label htmlFor="zoneId">Zone ID</Label>
          <Input
            placeholder="Zone ID"
            size="sm"
            type="text"
            name="zoneId"
            value={zoneId}
            onChange={handleChange}
          />

          <VStack spacing={4} pt="25px" align="stretch">
            <Checkbox
              spacing="4"
              size="md"
              colorScheme="green"
              name="edgeEnabled"
              isChecked={edgeEnabled || false}
              onChange={handleIsChecked}
            >
              Edge Enabled
            </Checkbox>

            <Checkbox
              spacing={4}
              size="md"
              colorScheme="green"
              name="personalizationEnabled"
              isChecked={personalizationEnabled || false}
              onChange={handleIsChecked}
            >
              Personalization Enabled
            </Checkbox>

            <Checkbox
              spacing={4}
              size="md"
              colorScheme="green"
              name="staticSiteEnabled"
              isChecked={staticSiteEnabled || false}
              onChange={handleIsChecked}
            >
              Static Site Enabled
            </Checkbox>
          </VStack>
          <Flex>
            <Label htmlFor="sessionContent">Session Content</Label>
            <Spacer />
            <Link
              textDecoration="underline"
              fontSize="sm"
              p="20px 0px 0px"
              onClick={() =>
                setStore((previous) => ({
                  ...previous,
                  sessionContent: sessionContentTemplate,
                }))
              }
            >
              Use Default Template
            </Link>
          </Flex>
          <Textarea
            h="300px"
            value={sessionContent}
            onChange={handleTextarea}
            placeholder="[]"
            size="sm"
            name="sessionContent"
          />

          <Label htmlFor="currencyMap">Currency Map</Label>
          <Textarea
            h="150px"
            value={currencyMap}
            onChange={handleTextarea}
            placeholder={'{"US": "USD", "CA": "CAD"}'}
            size="sm"
            name="currencyMap"
          />

          <Flex pt="25px" pb="50px">
            <Button variant="outline" onClick={handleDelete} type="button">
              Delete Store
            </Button>
            <Spacer />
            <Button variant="outline" colorScheme="blue" type="submit">
              Update Store
            </Button>
          </Flex>
        </form>
      </FormControl>
      <Heading pb={2} as="h2" size="md">
        Additonal Actions
      </Heading>
      <Button
        variant="outline"
        onClick={handleMarkOnboard}
        colorScheme="blue"
        type="button"
      >
        Mark Onboard
      </Button>
    </PageContainer>
  );
};
