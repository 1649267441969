import { Container } from "@chakra-ui/react";

// @IMPORTANT: 283px is the width of:
// + the sidebar (235px)
// + the left + right padding of the page content (25px + 25px)
// + the width of the scrollbar (8px)
export const PageContainer = (props: any) => {
  return (
    <Container maxWidth={"calc(100vw - 283px)"} {...props}>
      {props.children}
    </Container>
  );
};
