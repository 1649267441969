import { Button } from "@aws-amplify/ui-react";
import {
  Heading,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import { PageContainer } from "./PageContainer";
import { useNavigate } from "react-router";
import { TextRecord } from "../types";

type Props = {
  zoneId: string;
  textRecord?: TextRecord;
  storeId: string;
};

export const ConfirmationPage = ({ zoneId, textRecord, storeId }: Props) => {
  const navigate = useNavigate();
 
  let name;
  let value;

  if (textRecord) {
    name = textRecord.name;
    value = textRecord.value;
  }

  return (
    <PageContainer>
      <Heading pb={4} as="h1">
        {" "}
        Create New Customer{" "}
      </Heading>
      <Heading pb={4} fontSize="xl" as="h2">
        Congratulations! You have successfully created a new customer.
      </Heading>
      <Heading fontSize="lg" as="h3" pb={2} pt={4}>
        Cloudflare 
      </Heading>

      <TableContainer>
        <Table variant="unstyled" size="sm" width="40%">
          <TableCaption placement="bottom">
            Copy the following DNS TXT record and add it to your domain's DNS
            records.
          </TableCaption>
          <Tbody>
              {textRecord ? (
                <>
              <Tr>
                <Th>Text Record Name</Th>
                <Td>{name}</Td>
              </Tr>
              <Tr>
                <Th>Text Record Value</Th>
                <Td>{value}</Td>
              </Tr>
                </>
              ) : null }
            
            <Tr>
              <Th>Zone Id</Th>
              <Td>{zoneId}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Heading fontSize="xl" as="h2" pt={6} pb={6}>
        Next Steps
      </Heading>
      <Button onClick={() => navigate(0)}>Create Another Customer</Button>
      <Button onClick={() => navigate(`/store/${storeId}`) }>Edit Store</Button>
    </PageContainer>
  );
};
