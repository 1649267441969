import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Image, Spacer } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../App";
import { Link } from "../components/_ui/Link";
import logo from "../images/brand-logo.png";
import { StickyHeader } from "./StickyHeader";

export const ApplicationHeader = () => {
  const { signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user,
  ]);
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate("/login");
  };
  return (
    <StickyHeader>
      <Box
        display="flex"
        paddingBottom={0}
        minWidth="max-content"
        width="100%"
        height="100%"
        alignItems="center"
        gap="2"
      >
        <Box p="2">
          <Link to={ROUTES.HOME}>
            <Image src={logo} />
          </Link>
        </Box>

        <Spacer />
        <Link mr={25} onClick={handleSignOut} to={ROUTES.HOME}>
          Sign Out
        </Link>
      </Box>
    </StickyHeader>
  );
};
