import { extractPathname } from "./extractPathname";
type URLSlugValue = string;
type URLPatternPathname = string;

/**
 * MATCH:
 *   www.noliyoga.com/collections/all-styles
 *   www.noliyoga.com/collections/liquid
 *   www.noliyoga.com/collections/core
 * 
 * NO MATCH:
 *    www.noliyoga.com/collections/all-styles/products/brians-favorite-yoga-pants
 */
export const parseSlugValue = (pageUrl: string, pathPattern: URLPatternPathname | URLPatternPathname[]): URLSlugValue => {
    const pathToMatch = extractPathname(pageUrl);
  
    let pathPatterns = [];
    if (typeof pathPattern === "string") {
        pathPatterns = [pathPattern];
    } else {
        pathPatterns = pathPattern;
    }
    
    // No Match, return empty string
    let matchedValue = "";

    pathPatterns.forEach((pathPattern) => {
        let pattern = new URLPattern({ pathname: pathPattern })
        if (pattern.test({pathname: pathToMatch})) {
            const result = pattern.exec({pathname: pathToMatch});
            const slug = getSlugFrom(pathPattern);
            matchedValue = getSlugValue(slug, result);
        }
    });

    return matchedValue;
}

const getSlugFrom = (pathname: string): string | undefined => {
    const slug = pathname.split("/").find((segment) => segment.startsWith(":"));
    return slug;
}

/**
 * @param slug URL slug with or without the colon (':productHandle' or 'productHandle'). The slug must be used in the patternResult.
 */
const getSlugValue = (
    slug: string = "",
    patternResult: URLPatternResult | null
  ): string => {
    if (!slug) return "";
  
    if (slug.startsWith(":")) {
      slug = slug.replace(":", "");
    }
  
    if (patternResult && patternResult.pathname.groups[slug]) {
      let handle = patternResult.pathname.groups[slug];
      if (handle !== undefined) {
        return handle;
      }
    }
  
    return "";
  };
  