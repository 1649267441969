import { ExternalLinkIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";

type LinkButtonProps = {
  url: string;
};
export const LinkButton = ({ url }: LinkButtonProps) => {
  return (
    <IconButton
      ml={2}
      mr={2}
      size="xs"
      variant="ghost"
      colorScheme="gray"
      aria-label="External Link"
      fontSize="18px"
      onClick={() => window.open(url, "_blank")}
      icon={<ExternalLinkIcon />}
    />
  );
};
