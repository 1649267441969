import { Container } from "@chakra-ui/react";

/**
What makes a sticky header?

1. Set position to "sticky" to enable the sticky behavior.
2. Set top to 0 to make the header stick to the top of the browser window.
3. Adjusted the z-index value (if necessary) to ensure the header appears above other elements.
4. Set a backgroundColor to prevent content from showing through the header when scrolling.

position: sticky:
- A combination of relative and fixed positioning.
- An element with position: sticky behaves like a position: relative element until the user scrolls to a certain point, at which it becomes position: fixed.
- The top, right, bottom, or left property is used to define the point at which the element switches from relative to fixed positioning.
- The z-index property can be used to control stacking order.

<Container>
- is display: flex by default

How to center <Box> content vertically?
- display: flex
- alignItems: center
- <Box> then must have height set to 100%
*/
export const StickyHeader = ({ children }: Props) => {
    return (
        <Container
        maxWidth="container.xxl"
        centerContent
        pos="sticky"
        boxShadow= "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
        top={0}
        bgColor="white"
        zIndex={99}
        height="72px"
        alignItems={"center"}
      >
        {children}
        </Container>
    )
};

type Props = {
    children: React.ReactNode;
};
