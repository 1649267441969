import { useQuery } from "@tanstack/react-query";
import { fetchStores } from "../../lib/auth/fetchStores";
import { StoreTuple } from "../../types/responses";
import { Store } from "../../types/types";

export const useStores = () => {
  const config = {
    queryKey: ["stores"],
    queryFn: () => fetchStores(),
    select: (data: StoreTuple[]) =>
      data.map((store: StoreTuple): Store => store[1]),
  };

  return useQuery(config);
};
