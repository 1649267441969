import { SVC_URLS } from "../../config/SVC_URLS";
import { UpdateStoreRequest } from "../../types";
import { handleErrors } from "../http/handleErrors";
import { getIdToken } from "./getIdToken";
import { getUserRole } from "./getUserRole";

export const updateStore = async (storeId: string, storeData: UpdateStoreRequest) => {
  const idToken = await getIdToken();
  const role = await getUserRole();

  if (role === "CloudOps") {
    return await fetch(`${SVC_URLS["ecom"]}/store/${storeId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(storeData),
    }).then(handleErrors);
  }

  throw Error("User is not authorized to update a store.");
};