import { EditIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type StoreRowProps = {
  storeId: string | undefined;
};

export const StoreRowActions = ({ storeId = "" }: StoreRowProps) => {
  const navigate = useNavigate();

  return (
    <>
      <IconButton
        ml={2}
        mr={2}
        size="md"
        variant="ghost"
        colorScheme="gray"
        aria-label="Copy Store ID"
        fontSize="24px"
        onClick={() => navigate(`/store/${storeId}`)}
        icon={<EditIcon />}
      />

      <IconButton
        ml={2}
        mr={2}
        size="md"
        icon={<PlusSquareIcon />}
        variant="ghost"
        colorScheme="gray"
        fontSize="24px"
        aria-label="Create new experiment"
        onClick={() => navigate(`/store/${storeId}/create-experiment`)}
      />
    </>
  );
};
