import { SVC_URLS } from "../../config/SVC_URLS";
import { getIdToken } from "../auth/getIdToken";

export const markOnboard = async (ecomUrl: string) => {
  const idToken = await getIdToken();
  const requestBody = { message: `Deployment for ${ecomUrl}`, type: "onboard" };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(requestBody),
  };

  const response = await fetch(`${SVC_URLS["ops"]}/honeycomb`, options);

  if (!response.ok) {
    const { error } = await response.json();
    console.error(error);
    let message =
      "An unknown error has occurred. Please try again and contact support if it continues.";
    if (typeof error === "object" && error.message) {
      message = error.message;
    }
    throw new Error(message);
  }
  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(
      `Unable to parse JSON response body from POST ${SVC_URLS["ops"]}/honeycomb`
    );
  }
};
