import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { extractPathname } from "./lib/extractPathname";
import { parseSlugValue } from "./lib/parseSlugValue";
import { pagePatterns } from "./pagePatternOptions";

export type EditorContext = {
  url: string;
};

export type SuggestionContext = {
  pagePattern: string;
  pageSetName: string;
  suggestedPagePattern: string;
  url: string;
};

export type EditorContextType = {
  editorContext: EditorContext;
  setEditorContext: Dispatch<SetStateAction<EditorContext>>;
  suggestionContext: SuggestionContext;
  createPageSetSuggestion: (url: string) => void;
};

const Editor = createContext<EditorContextType>({
  editorContext: { url: "" },
  setEditorContext: () => {},
  suggestionContext: {
    pagePattern: "",
    pageSetName: "",
    url: "",
    suggestedPagePattern: "",
  },
  createPageSetSuggestion: () => {},
});

export const useEditorContext = () => useContext(Editor);

interface EditorProviderProps {
  children: React.ReactNode;
}

export const EditorProvider: React.FC<EditorProviderProps> = ({ children }) => {
  const [editorContext, setEditorContext] = useState<EditorContext>({
    url: "",
  });

  const [suggestionContext, setSuggestionContext] = useState<SuggestionContext>(
    { pagePattern: "", pageSetName: "", url: "", suggestedPagePattern: "" }
  );

  return (
    <Editor.Provider
      value={{
        editorContext,
        setEditorContext,
        suggestionContext,
        createPageSetSuggestion: createPageSetSuggestion(setSuggestionContext),
      }}
    >
      {children}
    </Editor.Provider>
  );
};

const createPageSetSuggestion =
  (setSuggestionContext: Dispatch<SetStateAction<SuggestionContext>>) =>
  (url: string) => {
    /** Scenario: Homepage */
    if (isHomepage(url)) {
      const pagePattern = pagePatterns.find(
        (option) => option.label === "Homepage"
      );

      if (pagePattern !== undefined) {
        const suggestedName = `${pagePattern.label}`;
        const suggestedPagePattern = pagePattern.value;

        setSuggestionContext((previous: SuggestionContext) => ({
          ...previous,
          pagePattern: pagePattern.value,
          pageSetName: suggestedName,
          suggestedPagePattern,
        }));
      }
    }

    /** Scenario: Collection Handle */
    const collectionHandle = parseSlugValue(url, "/collections/:collectionHandle");
    if (collectionHandle) {
      const pagePattern = pagePatterns.find(
        (option) => option.label === "Specific collection page"
      );
      if (pagePattern) {
        const suggestedName = `${pagePattern.label} - ${collectionHandle}`;
        const suggestedPagePattern = pagePattern.value.replace(
          "X",
          collectionHandle
        );

        setSuggestionContext((previous: SuggestionContext) => ({
          ...previous,
          pagePattern: pagePattern.value,
          pageSetName: suggestedName,
          suggestedPagePattern,
        }));
      }
    }

    /** Scenario: Product Handle */
    const productHandle = parseSlugValue(url, ["/products/:productHandle", "/collections/*/products/:productHandle"]);
    if (productHandle) {
      const pagePattern = pagePatterns.find(
        (option) => option.label === "Specific product page"
      );

      if (pagePattern !== undefined) {
        const suggestedName = `${pagePattern.label} - ${productHandle}`;
        const suggestedPagePattern = pagePattern.value.replace(
          "X",
          productHandle
        );

        setSuggestionContext((previous: SuggestionContext) => ({
          ...previous,
          pagePattern: pagePattern.value,
          pageSetName: suggestedName,
          suggestedPagePattern,
        }));
      }
    }
  };

const isHomepage = (url: string) => {
  const pathname = extractPathname(url);
  const pattern = new URLPattern({ pathname: "/" });
  return pattern.test({ pathname });
};
