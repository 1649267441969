import { useState, useRef, useEffect } from "react";
import { EMBEDDED_QUERY_PARAMETER } from "../EMBEDDED_QUERY_PARAMETER";
import { useStore } from "../../../hooks/queries/useStore";
import { Store } from "../../../types";
import { useEditorContext } from "../EditorProvider";
import { loadIframe } from "../loadIframe";

export const useExperimentationEditor = () => {
  const [mouseover, setMouseover] = useState("");
  const iframeRef: React.RefObject<HTMLIFrameElement> = useRef(null);
  const { data: store, isLoading } = useStore({});
  const [storeBaseUrl, setStoreBaseUrl] = useState("");
  const { setEditorContext } = useEditorContext();

  /** On page load */
  useEffect(() => {
    initializeIframe(iframeRef);

    window.addEventListener("resize", resizeIframeToParentSize(iframeRef));

    window.onmessage = (event) => {
      // @ToDo: security check to make sure the message is coming from the expected origin
      // console.debug(event.origin);
      const { name, data } = event.data;
      switch (name) {
        case "iframe-loaded":
          console.debug("[Portal] iframe-loaded");
          break;

        case "mouseover":
          // console.debug("[Portal] mouseover", data.htmlTag);
          setMouseover(data.htmlTag);
          break;

        case "click":
          console.debug("[Portal] click", data);
          break;
      }
    };

    return () => {
      window.onmessage = null;
    };
  }, []);

  // Set the iframe src attribute when the storeBaseUrl changes
  useEffect(() => {
    if (iframeRef.current !== null && storeBaseUrl !== "") {
      const url = loadIframe(iframeRef, storeBaseUrl);
      setEditorContext((prev) => ({...prev, url}));
    }
  }, [iframeRef, storeBaseUrl, setEditorContext]);

  // Set the storeBaseUrl when the store changes
  useEffect(() => {
    if (store === undefined || isLoading) return;
    setStoreBaseUrl(buildStoreUrl(store));
  }, [store, isLoading]);

  // Return the public API for the ExperimentationEditor hook
  const baseUrl = stripEmbeddedParameter(storeBaseUrl);
  return { mouseover, iframeRef, storeBaseUrl: baseUrl, store };
};

/**
 * Private functions
 */
const stripEmbeddedParameter = (url: string): string => {
  const search = EMBEDDED_QUERY_PARAMETER;
  const replace = "";
  return url.replace(search, replace);
};

const resizeIframeToParentSize =
  (iframeRef: React.RefObject<HTMLIFrameElement>) => () => {
    iframeRef.current?.setAttribute("width", window.innerWidth - 30 + "px");
    iframeRef.current?.setAttribute("height", window.innerHeight - 1 + "px");
  };

const initializeIframe = (iframeRef: React.RefObject<HTMLIFrameElement>) => {
  if (iframeRef.current === null) throw new Error("iframeRef is null");
  const width = window.screen.width - 30;
  iframeRef.current.setAttribute("width", width.toString());
};

const buildStoreUrl = (store: Store) => {
  if (store.customerUrl !== "localhost:8080") {
    return "https://" + store.customerUrl + EMBEDDED_QUERY_PARAMETER;
  } else {
    return "http://" + store.customerUrl + EMBEDDED_QUERY_PARAMETER;
  }
};
