import { SVC_URLS } from "../../config/SVC_URLS";
import { CreatePageSetRequest } from "../../types";
import { getIdToken } from "../auth/getIdToken";

export const createPageSet = async (request: CreatePageSetRequest) => {
  const idToken = await getIdToken();
  const response = await fetch(
    `${SVC_URLS["ecom"]}/store/${request.storeId}/pagesets`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(request),
    }
  );
  const data = await response.json();
  console.debug("[createPageSet] response", data);
  return data;
};
