import { SVC_URLS } from "../../config/SVC_URLS";
import { handleErrors } from "../http/handleErrors";
import { getIdToken } from "./getIdToken";
import { getUserRole } from "./getUserRole";

export const deleteStore = async (storeId: string) => {
  const role = await getUserRole();
  const idToken = await getIdToken();
  if (role === "CloudOps") {
    return fetch(`${SVC_URLS["ecom"]}/store/${storeId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    }).then(handleErrors);
  }

  throw Error("User is not authorized to delete a store.");
};
