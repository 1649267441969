import { Heading, Text } from "@chakra-ui/react";
import { PageContainer } from "./PageContainer";

export const Home = () => (
  <PageContainer>
    <Heading pb={4} as="h1">
      Welcome
    </Heading>
    <Text textStyle={"text-body-regular"}>
      Human and machine driven diagnoses and recommendations for accelerating
      your ecommerce store
    </Text>
  </PageContainer>
);
