import { SVC_URLS } from "../../config/SVC_URLS";
import { getIdToken } from "../auth/getIdToken";

type StoreId = string;

export const fetchStore = async (storeId: StoreId) => {
  const idToken = await getIdToken();
  const response = await fetch(`${SVC_URLS["ecom"]}/store/${storeId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
  const data = await response.json();
  return data;
};
