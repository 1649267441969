import { CreatePageSetRequest, PageSetFormData } from "../../../../types";

export const buildPageSetRequest = (
  formData: PageSetFormData,
  storeId: string = "",
  orgId: string = ""
): CreatePageSetRequest => {
  if (!storeId) throw new Error("storeId is required");
  if (!orgId) throw new Error("orgId is required");

  const { name, pagePattern, pageType } = formData;
  return {
    name,
    pagePattern,
    pageType,
    storeId,
    orgId,
  };
};
