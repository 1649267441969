import {
  AbsoluteCenter,
  Flex,
  Heading,
  Link,
  Spinner,
  TableContainer,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { DataTable } from "../../components/DataTable";
import { CopyButton } from "../../components/_ui/CopyButton";
import { SVC_URLS } from "../../config/SVC_URLS";
import { useStores } from "../../hooks/queries/useStores";
import { renderBoolean } from "../../lib/table/renderBoolean";
import { truncate } from "../../lib/truncate";
import { Store } from "../../types/types";
import { PageContainer } from "../PageContainer";
import { LinkButton } from "./LinkButton";
import { StoreRowActions } from "./StoreRowActions";

type StoreRow = Store;

export const Stores = () => {
  const [rows, setRows] = useState<StoreRow[]>([]);
  const { data: stores, isError, isLoading } = useStores();
  const showToast = useToast();

  useEffect(() => {
    if (isError) {
      showToast({
        title: "Error",
        description: "There was an error loading stores",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }

    if (stores) {
      setRows(stores);
    }
  }, [stores, isError, showToast]);

  return (
    <PageContainer>
      {isLoading ? (
        <AbsoluteCenter>
          <Spinner />
        </AbsoluteCenter>
      ) : (
        <>
          <Heading pb={4} as="h1">
            Stores
          </Heading>
          <TableContainer maxW={"100%"} overflowX="auto">
            <DataTable columns={columns} data={rows} />
          </TableContainer>
        </>
      )}
    </PageContainer>
  );
};

const columnHelper = createColumnHelper<Store>();
const columns = [
  columnHelper.display({
    id: "actions",
    cell: (props) => (
      <StoreRowActions storeId={props.row.original.id}></StoreRowActions>
    ),
    header: "Actions",
  }),
  columnHelper.accessor("id", {
    cell: (props) => (
      <Flex>
        <Tooltip label={"Copy to Clipboard"} aria-label="A tooltip">
          <Text as="span" noOfLines={1}>
            <CopyButton value={props.row.original.id}></CopyButton>
          </Text>
        </Tooltip>

        <Tooltip label={props.row.original.id} aria-label="A tooltip">
          <Text as="span" noOfLines={1}>
            {truncate(props.getValue(), 4)}
          </Text>
        </Tooltip>
      </Flex>
    ),
    header: "Id",
  }),

  columnHelper.accessor("updatedAt", {
    cell: (props) => {
      const updatedAtDate = props.getValue();
      if (!updatedAtDate) {
        return "-";
      }
      const date = new Date(updatedAtDate);
      return date.toISOString().slice(0, 16).replace("T", " ");
    },
    header: "Updated At",
  }),

  columnHelper.accessor("customerUrl", {
    cell: (props) => (
      <>
        <LinkButton url={`https://${props.getValue()}`} />
        <Link as={ReactRouterLink} to={`https://${props.getValue()}`}>
          {props.getValue()}
        </Link>
      </>
    ),
    header: "Customer URL",
  }),

  columnHelper.accessor("edgeEnabled", {
    cell: (props) => renderBoolean(props.getValue()),
    header: "Edge Delivery",
  }),

  columnHelper.accessor("personalizationEnabled", {
    cell: (props) => renderBoolean(props.getValue()),
    header: "Personalization",
  }),
  columnHelper.accessor("staticSiteEnabled", {
    cell: (props) => renderBoolean(props.getValue()),
    header: "Static Site",
  }),
  columnHelper.accessor("cacheDuration", {
    cell: (props) => (
      <Flex>
        <Tooltip label="Copy Purge URL to Clipboard" aria-label="A tooltip">
          <Text as="span" noOfLines={1}>
            <CopyButton
              value={`${SVC_URLS["ops"]}/cache/purge/${props.row.original.id}`}
            ></CopyButton>
          </Text>
        </Tooltip>
        {props.getValue()}
      </Flex>
    ),
    header: "Cache",
  }),
  columnHelper.accessor("currencyDefault", {
    cell: (props) => (props.getValue() ? props.getValue() : "-"),
    header: "Currency",
  }),
];
