import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  AbsoluteCenter,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageContainer } from "../pages/PageContainer";

export const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <PageContainer
      h="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <AbsoluteCenter>
        <VStack>
          <Heading as="h1">Get started with Nostra today</Heading>
          <Text pb="25px" textStyle={"text-body-regular"}>
            Human and machine driven diagnoses and recommendations for
            accelerating your ecommerce store
          </Text>
          <View className="auth-wrapper">
            <Authenticator hideSignUp={true}></Authenticator>
          </View>
        </VStack>
      </AbsoluteCenter>
    </PageContainer>
  );
};
