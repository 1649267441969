import { EMBEDDED_QUERY_PARAMETER } from "./EMBEDDED_QUERY_PARAMETER";
import { hasProtocol } from "./lib/hasProtocol";

export const loadIframe = (
  iframeRef: React.RefObject<HTMLIFrameElement>,
  url: string
): string => {
  if (iframeRef.current === null) throw new Error("iframe is not available");
  const absoluteUrl = formatUrl(url);
  iframeRef.current.src = absoluteUrl;

  return sanitizeUrl(absoluteUrl);
};

const sanitizeUrl = (url: string): string => {
  const sanitized = new URL(url);
  sanitized.searchParams.delete("internal-embedded");
  return sanitized.toString();
}

const formatUrl = (url: string): string => {
  if (!hasProtocol(url)) {
    url = "https://" + url;
  }

  // Add internal-embedded query parameter if it doesn't exist
  if (!hasSearchParams(url)) {
    url = url + EMBEDDED_QUERY_PARAMETER;
  } else if(!hasSearchParameter(url, "internal-embedded")) {
    url = url + "&internal-embedded";
  }

  return url;
};

const hasSearchParams = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.search.length > 0;
  } catch (error) {
    // Invalid URL
    console.error("Invalid URL:", url, error);
    return false;
  }
};

const hasSearchParameter = (url: string, parameter: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.has(parameter);
  } catch (error) {
    // Invalid URL
    console.error("Invalid URL:", url, error);
    return false;
  }
}
