import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router";
import { ApplicationHeader } from "./ApplicationHeader";
import { ApplicationSideBar } from "./ApplicationSideBar";

export const Layout = () => {
  return (
    <Grid
      templateAreas={`"header header"
                        "nav main"`}
      gridTemplateRows={"72px 1fr"}
      gridTemplateColumns={"236px 1fr"}
      h="100vh"
    >
      {/* header */}
      <GridItem area={"header"}>
        <ApplicationHeader></ApplicationHeader>
      </GridItem>

      {/* left nav */}
      <GridItem area={"nav"}>
        <ApplicationSideBar />
      </GridItem>

      {/* page content */}
      <GridItem pl={25} pt={25} pr={25} area={"main"}>
        <Outlet />
      </GridItem>
    </Grid>
  );
};
