import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Label } from "../components/_ui/Label";
import { useOnboardCustomer } from "../hooks/queries/useOnboardCustomer";
import { generateRandomPassword } from "../lib/auth/generateRandomPassword";
import { CreateOnboardingCustomerRequest, TextRecord } from "../types";
import { PageContainer } from "./PageContainer";
import { ConfirmationPage } from "./ConfirmationPage";

export const NewCustomer = () => {
  const showToast = useToast();
  const {
    handleSubmit,
    register,
    reset,
  } = useForm<NewCustomerFormData>({
    defaultValues: initialFormValues,
  });

  const onSuccess = (newCustomerResponse: any) => {
    showToast({
      title: "Success",
      description: "Customer created successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    reset(initialFormValues);
  };

  const onError = (error: any) => {
    showToast({
      title: error.name || "Error",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const createOnboardingCustomerMutation = useOnboardCustomer();

  if (createOnboardingCustomerMutation.isSuccess) {
    const zoneId: string = createOnboardingCustomerMutation.data.store.zoneId;
    const storeId: string = createOnboardingCustomerMutation.data.store.id;
    const textRecord: TextRecord = createOnboardingCustomerMutation.data.txtRecord;
    console.log("zoneId: ", zoneId);
    console.log("textRecord: ", textRecord);
    console.log("storeId: ", storeId);
    return (
      <ConfirmationPage
        zoneId={zoneId}
        textRecord={textRecord}
        storeId={storeId}
      />
    );
  }

  const onSubmit: SubmitHandler<NewCustomerFormData> = async (formData) => {
    const request: CreateOnboardingCustomerRequest =
      createOnboardingCustomerRequest(formData);

    createOnboardingCustomerMutation.mutate(request, {
      onError,
      onSuccess,
    });
  };

  return (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading pb={4} as="h1">
          Create New Customer
        </Heading>
        <FormControl maxWidth={"40%"}>
          <Label htmlFor="name">Full Name</Label>
          <Input
            type="text"
            {...register("name", {
              required: true,
            })}
          />

          <Label htmlFor="orgName">Organization Name</Label>
          <Input
            type="text"
            {...register("orgName", {
              required: true,
            })}
          />

          <Label htmlFor="orgName">Store Name</Label>
          <Input
            type="text"
            {...register("storeName", {
              required: true,
            })}
          />

          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            {...register("email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
          />

          <Label htmlFor="name">Customer URL</Label>
          <Input
            type="text"
            {...register("customerUrl", {
              required: true,
            })}
          />
          <Label htmlFor="name">Ecom URL</Label>
          <Input
            type="text"
            {...register("ecomUrl", {
              required: true,
            })}
          />
          <Flex pt="25px" pb="50px">
            <Spacer />
            <Button type="submit" colorScheme="blue" variant="outline" mr={3}>
              Create Customer
            </Button>
          </Flex>
        </FormControl>
      </form>
    </PageContainer>
  );
};

const createOnboardingCustomerRequest = (
  data: NewCustomerFormData
): CreateOnboardingCustomerRequest => {
  const tempPassword = generateRandomPassword();
  return {
    storeName: data.storeName,
    orgName: data.orgName,
    email: data.email,
    name: data.name,
    password: tempPassword,
    customerUrl: data.customerUrl,
    ecomUrl: data.ecomUrl,
  };
};

const initialFormValues = {
  name: "",
  orgName: "",
  storeName: "",
  email: "",
  customerUrl: "",
  ecomUrl: "",
};

type NewCustomerFormData = {
  orgName: string;
  storeName: string;
  email: string;
  name: string;
  customerUrl: string;
  ecomUrl: string;
};