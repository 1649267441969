import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CopyButton } from "../../components/_ui/CopyButton";
import { PageSet } from "../../types";

type Props = {
  pageSet: PageSet;
  isOpen: boolean;
  onClose: () => void;
};

export const CreatePageSetConfirmationModal = ({
  pageSet,
  isOpen,
  onClose,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <Heading as="h1" size="lg">
            Confirmation
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading as="h2" size="md" mb={4}>
            {pageSet.name}
          </Heading>

          <VStack alignItems={"left"}>
            <HStack>
              <CopyButton value={pageSet.id} mr={0} />
              <Text as="b">Id</Text>
              <Spacer />
              <Text>{pageSet.id}</Text>
            </HStack>
            <HStack>
              <CopyButton value={pageSet.storeId} mr={0} />
              <Text as="b">Store Id</Text>
              <Spacer />
              <Text>{pageSet.storeId}</Text>
            </HStack>
            <HStack>
              <CopyButton value={pageSet.orgId} mr={0} />
              <Text as="b">Org Id</Text>
              <Spacer />
              <Text>{pageSet.orgId}</Text>
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
