import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
  Text,
  Heading,
} from "@chakra-ui/react";
import React, { FormEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Label } from "../../components/_ui/Label";
import { useCreatePageSet } from "../../hooks/queries/useCreatePageSet";
import { useExperimentationEditor } from "./hooks/useExperimentationEditor";
import { CreatePageSetRequest, PageSet, PageSetFormData } from "../../types";
import { CreatePageSetConfirmationModal } from "./CreatePageSetConfirmationModal";
import { useEditorContext } from "./EditorProvider";
import { validateUrlMatchesRegex } from "./lib/forms/validateUrlMatchesRegex";
import { loadIframe } from "./loadIframe";
import { pagePatterns } from "./pagePatternOptions";
import { validateRegularExpression } from "./lib/forms/validateRegularExpression";
import { FieldError } from "../../components/_ui/forms/FieldError";
import { buildPageSetRequest } from "./lib/requests/buildPageSetRequest";

export const Editor = () => {
  const { iframeRef, store } = useExperimentationEditor();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const createPageSetMutation = useCreatePageSet();
  const showToast = useToast({
    position: "bottom",
    duration: 3000,
    isClosable: true,
  });
  const {
    editorContext,
    setEditorContext,
    suggestionContext,
    createPageSetSuggestion,
  } = useEditorContext();

  const [pageSet, setPageSet] = React.useState<PageSet | null>(null);
  const { openConfirmation, isConfirmationOpen, closeConfirmation } =
    useConfirmationPage();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<PageSetFormData>({
    defaultValues: initialFormValues,
  });

  const onSubmit = async (formData: PageSetFormData) => {
    if (store === undefined) return void 0;

    const request: CreatePageSetRequest = buildPageSetRequest(
      formData,
      store.id,
      store.orgId
    );

    try {
      const pageSet: PageSet = await createPageSetMutation.mutateAsync(request);
      setPageSet(pageSet);
      openConfirmation();

      showToast({
        title: "Success",
        description: request.name + " - created successfully.",
        status: "success",
      });
    } catch (error) {
      showToast({
        title: "Error",
        description: "An error occurred while creating the PageSet.",
        status: "error",
      });
    }
  };

  const populateForm = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    const defaultName: string =
      pagePatterns.find((option) => option.value === value)?.label || "";

    /** @ToDo: When user switches scenario, give a suggestion if appropriate */
    setValue("pagePattern", value);
    setValue("name", defaultName);
  };

  const onSuccessfulSubmit = () => {
    onClose();
    reset(initialFormValues);
  };

  useEffect(onSuccessfulSubmit, [isSubmitSuccessful, reset, onClose]);

  const closeModal = () => {
    reset(initialFormValues);
    onClose();
  };

  const openNewExperiment = () => {
    const { url } = editorContext;
    createPageSetSuggestion(url);
    onOpen(); // open "New Experiment" modal
  };

  /**
   * When the suggestionContext changes, update the form values
   * @ToDo: See if we use a "controlled" form if there is a better way to do this -
   *        Or at least move this and the modal markup into another component.
   */
  useEffect(() => {
    if (suggestionContext) {
      const { suggestedPagePattern, pageSetName } = suggestionContext;
      setValue("pagePattern", suggestedPagePattern);
      setValue("name", pageSetName);
    }
  }, [suggestionContext, setValue]);

  return (
    <VStack>
      <FormControl>
        <form
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const elements = event.currentTarget.elements;
            const pageUrl = (elements.namedItem("PageUrl") as HTMLInputElement)
              .value;

            // Load the iFrame
            const url = loadIframe(iframeRef, pageUrl);

            // Update the EditorContext to track the URL loaded in the iFrame
            // @ToDo: In the future, rely on a message from the iFrame q
            setEditorContext((prev) => ({ ...prev, url }));
          }}
        >
          {" "}
          <HStack>
            <Label htmlFor="PageUrl">Page URL</Label>
            <Input
              type="text"
              name="PageUrl"
              defaultValue={store?.customerUrl || ""}
              htmlSize={4}
              width={"400px"}
            />
            <Button
              colorScheme="blue"
              variant="ghost"
              type="submit"
              mt={4}
              mb={2}
            >
              Load Page
            </Button>
            <Button
              colorScheme="blue"
              variant="ghost"
              mt={4}
              mb={2}
              onClick={openNewExperiment}
            >
              New Experiment
            </Button>
          </HStack>
        </form>
      </FormControl>

      {pageSet && (
        <CreatePageSetConfirmationModal
          pageSet={pageSet}
          isOpen={isConfirmationOpen}
          onClose={closeConfirmation}
        />
      )}

      <Modal isOpen={isOpen} onClose={closeModal} size="xl">
        <ModalOverlay />

        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              <Heading as="h1" size="lg">
                Create Experiment
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <FormControl>
                  <HStack alignItems={"baseline"}>
                    <Text as="b">URL</Text>
                    <Text>{editorContext.url}</Text>
                  </HStack>

                  <Label htmlFor="applyTo">Apply to</Label>
                  <Select
                    placeholder="Select option"
                    name="applyTo"
                    defaultValue={suggestionContext.pagePattern}
                    onChange={populateForm}
                    isRequired={true}
                  >
                    {pagePatterns.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>

                  <Label htmlFor="name">Name</Label>
                  <Input
                    type="text"
                    defaultValue={suggestionContext.pageSetName}
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    })}
                    isInvalid={!!errors.name}
                  />
                  <FieldError>{errors.name?.message}</FieldError>

                  <Label>RegEx</Label>
                  <Textarea
                    h="150px"
                    {...register("pagePattern", {
                      required: true,
                      validate: {
                        validRegex: validateRegularExpression,
                        urlMatchesRegex: validateUrlMatchesRegex(editorContext.url),
                      },
                    })}
                    isInvalid={!!errors.pagePattern}
                  />
                 <FieldError>{errors.pagePattern?.message}</FieldError>

                  <Input
                    type="hidden"
                    value={initialFormValues.pageType}
                    {...register("pageType")}
                  />
                </FormControl>
              </>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="blue" variant="ghost" mr={3}>
                Create
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Flex>
        <Box>
          <iframe
            id="iFrame"
            height="750px"
            title="Experimentation Creator"
            ref={iframeRef}
          ></iframe>
        </Box>
      </Flex>
    </VStack>
  );
};

const initialFormValues = {
  name: "",
  samplePageUrl: "",
  pageType: "experiment",
  pagePattern: "",
  applyTo: "",
};

const useConfirmationPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    isConfirmationOpen: isOpen,
    openConfirmation: onOpen,
    closeConfirmation: onClose,
  };
};
