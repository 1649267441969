import { Container, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router";
import { ApplicationHeader } from "./ApplicationHeader";

export const ExperimentationEditorLayout = () => {
  return (
    <>
      <ApplicationHeader></ApplicationHeader>

      <Container minWidth="xxl" centerContent>
        <Flex
          minWidth="max-content"
          width="100%"
          alignItems="center"
        >
          <Outlet />
        </Flex>
      </Container>
    </>
  );
};
