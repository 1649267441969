import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Route, Routes } from "react-router-dom";
import { Login } from "./authentication/Login";
import { ExperimentationEditorLayout } from "./layouts/ExperimentationEditorLayout";
import { Layout } from "./layouts/Layout";
import { getRole } from "./lib/auth/getRole";
import { Editor } from "./pages/Editor/Editor";
import { EditorProvider } from "./pages/Editor/EditorProvider";
import { Home } from "./pages/Home";
import { NewCustomer } from "./pages/NewCustomer";
import { StorePage } from "./pages/StorePage";
import { Stores } from "./pages/Stores/Stores";

export const ROUTES = {
  HOME: "/",
  STORES: "/stores",
  STORE_EDIT: "/store/:storeId",
  CREATE_CUSTOMER: "/new-customer",
  CREATE_EXPERIMENT: "/store/:storeId/create-experiment",
};

const MyRoutes = () => {
  const { route, user } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);

  const isAuthenticated = route === "authenticated";
  const isCloudOpsRole = getRole(user) === "CloudOps" && isAuthenticated;

  return (
    <Routes>
      {/** Authenticated ONLY ROUTES */}
      {isAuthenticated && (
        <>
          {/** Standard Layout */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            {/** CloudOps ONLY ROUTES */}
            {isCloudOpsRole ? (
              <>
                <Route path={ROUTES.STORES} element={<Stores />} />
                <Route path={ROUTES.STORE_EDIT} element={<StorePage />} />
                <Route
                  path={ROUTES.CREATE_CUSTOMER}
                  element={<NewCustomer />}
                />
              </>
            ) : (
              <>
                <Route
                  path={ROUTES.STORES}
                  element="403 Forbidden - You do not have permissions to view this page"
                ></Route>
                <Route
                  path="/store/:storeId"
                  element="403 Forbidden - You do not have permissions to view this page"
                />
              </>
            )}
          </Route>
          {/** Editor Layout */}
          {isCloudOpsRole ? (
            <Route
              path={ROUTES.CREATE_EXPERIMENT}
              element={<ExperimentationEditorLayout />}
            >
              <Route index element={<EditorProvider children={<Editor />} />} />
            </Route>
          ) : (
            <Route
              path={ROUTES.CREATE_EXPERIMENT}
              element="403 Forbidden - You do not have permissions to view this page"
            />
          )}
        </>
      )}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element="404 Not Found" />
    </Routes>
  );
};

export const App = () => (
  <Authenticator.Provider>
    <MyRoutes />
  </Authenticator.Provider>
);
