import { SVC_URLS } from "../../config/SVC_URLS";
import { StoreTuple } from "../../types/responses";
import { getIdToken } from "./getIdToken";
import { getUserRole } from "./getUserRole";

export const fetchStores = async (): Promise<StoreTuple[]> => {
  const role = await getUserRole();
  const idToken = await getIdToken();

  if (role === "CloudOps") {
    const response = await fetch(`${SVC_URLS["ecom"]}/store/all`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return await response.json();
  } else {
    throw new Error("User is not authorized to view stores");
  }
};
