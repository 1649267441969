import {
  ChakraProvider,
  ColorModeScript,
  extendBaseTheme,
  isChakraTheme,
  theme as baseTheme,
} from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { impulsumTheme } from "./theme/impulsumTheme";
import "@fontsource/inter/400.css";
import Fonts from "./components/_ui/Fonts";
import {
  ThemeProvider as AmplifyThemeProvider,
  Theme as AmplifyTheme,
} from "@aws-amplify/ui-react";

// Conditional ESM module loading (Node.js and browser)
// @ts-ignore: Property 'UrlPattern' does not exist 
if (!globalThis.URLPattern) { 
  import("urlpattern-polyfill").then(() => {
    // The polyfill has been loaded, you can now use `URLPattern` in this module
  }).catch((err) => {
    // Handle any errors that occurred while loading the polyfill
    console.error("Failed to load URLPattern polyfill", err);
  });
}

const queryClient = new QueryClient();

const validateConfiguration = (configuration: any) => {
  Object.entries(configuration).forEach((config) => {
    if (!config[1]) {
      console.error(configuration);
      throw new Error(
        `AWS Cognito Configuration Missing: '${config[0]}: ${config[1]}'`
      );
    }
  });
};

const cognitoConfiguration = {
  region: process.env.REACT_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOLID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENTID,
};
validateConfiguration(cognitoConfiguration);
Amplify.configure(cognitoConfiguration);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

/**
 * Chakra Theme
 */
if (!isChakraTheme(impulsumTheme)) {
  console.warn("Warning: Impulsum Theme is not a valid Chakra theme.");
}
const extendedTheme = extendBaseTheme(impulsumTheme, baseTheme);

/**
 * Amplify Theme
 */
const theme: AmplifyTheme = {
  name: "my-theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          80: { value: "#011639"}, // nostra dark blue
          90: { value: "#273b5a" },
        },
        // ...
      },
    },
  },
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={extendedTheme}>
        <Fonts />
        <ColorModeScript />
        <AmplifyThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </AmplifyThemeProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
