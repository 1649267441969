import { useAuthenticator } from "@aws-amplify/ui-react";
import { HStack, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../App";
import { Link } from "../components/_ui/Link";
import { getRole } from "../lib/auth/getRole";

export const ApplicationSideBar = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const isCloudOpsRole = getRole(user) === "CloudOps";
  return (
    <MenuContainer>
      <MenuItem to={ROUTES.HOME}>
        <Link to={ROUTES.HOME}>Home</Link>
      </MenuItem>
      {isCloudOpsRole && (
        <>
          <MenuItem to={ROUTES.STORES}>
            <Link to={ROUTES.STORES}>Stores</Link>
          </MenuItem>

          <MenuItem to={ROUTES.CREATE_CUSTOMER}>
            <Link to={ROUTES.CREATE_CUSTOMER}>New Customer</Link>
          </MenuItem>
        </>
      )}
    </MenuContainer>
  );
};

const MenuContainer = ({ children }: any) => {
  return (
    <VStack
      flexDirection={"column"}
      alignItems={"center"}
      align="stretch"
      padding={"24px 12px"}
      gap={"8px"}
      width={"235px"}
      height={"100%"}
      justifyContent={"flex-start"}
      bgColor={"#F6FAFF"}
      h="100%"
    >
      {children}
    </VStack>
  );
};

const MenuItem = ({ children, to = "/" }: any) => {
  const navigate = useNavigate();
  return (
    <HStack
      padding={"4px 12px"}
      gap={"12px"}
      width={"211px"}
      height={"40px"}
      _hover={{
        backgroundColor: "#E8E8FD",
        borderRadius: "8px",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(to);
      }}
      style={{ marginTop: 0 }}
    >
      {children}
    </HStack>
  );
};

// const MenuDivider = () => {
//   return (
//     <StackDivider
//       height={"1px"}
//       width={"222px"}
//       left={"0px"}
//       top={"251px"}
//       borderRadius={"0px"}
//       padding={"0px 0px 0px 24px"}
//       bgColor={"#D2D9E9"}
//       // borderColor="gray.200"
//     />
//   );
// };
