import { CopyIcon } from "@chakra-ui/icons";
import { IconButton, useClipboard, useToast } from "@chakra-ui/react";

type CopyButtonProps = {
  value: string | undefined;
  ml?: number;
  mr?: number;
};

export const CopyButton = ({ value, ml, mr }: CopyButtonProps) => {
  const { onCopy } = useClipboard(value || "");
  const showToast = useToast();
  const copy = () => {
    onCopy();
    showToast({
      title: "Copied to clipboard",
      description: value,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  if (ml === undefined) {
    ml = 2;
  }

  if (mr === undefined) {
    mr = 2;
  }

  return (
    <IconButton
      ml={ml}
      mr={mr}
      size="xs"
      variant="ghost"
      colorScheme="gray"
      aria-label="Copy Store ID"
      fontSize="18px"
      onClick={copy}
      icon={<CopyIcon />}
    />
  );
};
