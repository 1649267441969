import { hasProtocol } from "./hasProtocol";

export const extractPathname = (pageUrl: string) => {
  let path = "";
  if (!hasProtocol(pageUrl)) {
    pageUrl = "https://" + pageUrl;
  }

  try {
    const url = new URL(pageUrl);
    path = url.pathname;
  } catch (error) {
    // not an absolute url
    const isPath = pageUrl.startsWith("/");
    if (!isPath) {
      throw new Error(`Invalid url: ${pageUrl}`);
    }
    path = pageUrl;
  }
  return path;
};
