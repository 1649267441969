import { chakra } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
// @ToDo: Remove 'any' type
export const Link = (props: any) => (
  <chakra.link as={ReactRouterLink} color={"text-link-primary-default"}
  _hover={{
    color(theme) {
        return theme.colors["text-link-primary-hover"]
    },
  }}
  _activeLink={{
    color(theme) {
        return theme.colors["text-link-primary-pressed"]
    },
  }} {...props} />
);
