import { SVC_URLS } from "../../config/SVC_URLS";
import { CreateOnboardingCustomerRequest } from "../../types/requests";
import { getIdToken } from "../auth/getIdToken";

export const onboardCustomer = async (newCustomer: CreateOnboardingCustomerRequest): Promise<any> => {
  const idToken = await getIdToken();
  const response = await fetch(
    `${SVC_URLS["auth"]}/onboard`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`,
      },
      body: JSON.stringify(newCustomer),
    }
  );

  if (!response.ok) {
    const { error } = await response.json();
    console.error(error);

    let message = "An unknown error has occurred. Please try again and contact support if it continues.";
    if (typeof error === 'object' && error.message) {
      message = error.message;
    }
    throw new Error(message);
  }

  try {
    const data = await response.json();
    return data;
  } catch(error) {
    console.error(error);
    throw new Error(`Unable to parse JSON response body from POST ${SVC_URLS["auth"]}/onboard`);
  }
};